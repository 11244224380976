export const signIn = (payload) => ({
	type: "SIGN_IN",
	payload,
});

export const signOut = () => ({
	type: "SIGN_OUT",
});

export const updateUser = (payload) => ({
	type: "UPDATE_USER",
	payload,
});

export const addOrganizations = (payload) => ({
	type: "ADD_ORGANIZATIONS",
	payload,
});

export const removeOrganizations = () => ({
	type: "REMOVE_ORGANIZATIONS",
});

export const addProfessions = (payload) => ({
	type: "ADD_PROFESSIONS",
	payload,
});

export const removeProfessions = () => ({
	type: "REMOVE_PROFESSIONS",
});

export const addKeyfields = (payload) => ({
	type: "ADD_KEYFIELDS",
	payload,
});

export const removeKeyfields = () => ({
	type: "REMOVE_KEYFIELDS",
});

export const setSelectedEvent = (payload) => ({
	type: "SET_SELECTED_EVENT",
	payload,
});
export const setEventList = (payload) => ({
	type: "SET_EVENT_LIST",
	payload,
});
