import {makeStyles} from "@material-ui/core";
import React from "react";
import {Redirect, Route, Switch} from "react-router";
import {useSelector} from "react-redux";

import {hasPermissions} from "../../helpers";
import Root from "./root";
import Create from "./create";

const useStyles = makeStyles({
	container: {
		flexGrow: 1,
	},
});

export default function Organizations() {
	const classes = useStyles();
	const user = useSelector((state) => state.user);
	const isAdmin = hasPermissions("admin", user.user_permissions);
	return (
		<div className={classes.container}>
			<Switch>
				{isAdmin && (
					<Route path="/organizations/edit/:id" exact>
						<Create edit />
					</Route>
				)}
				{isAdmin && (
					<Route path="/organizations/create" exact>
						<Create />
					</Route>
				)}
				<Route path="/organizations" exact>
					<Root />
				</Route>
				<Route path="/organizations">
					<Redirect to="/organizations" />
				</Route>
			</Switch>
		</div>
	);
}
