import {
	Button,
	CircularProgress,
	Divider,
	Grow,
	makeStyles,
	Paper,
	TextField,
} from "@material-ui/core";
import {Form, Formik} from "formik";
import {useSnackbar} from "notistack";
import React, {useState} from "react";
import validator from "validator";
import {Logo, SignWithMicrosoft} from "../components";
import firebase, {auth} from "../config/firebase";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
	auth_container: {
		width: 320,
		padding: theme.spacing(2),
	},
	divider: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	textfields: {
		width: "100%",
		marginBottom: 15,
	},
	submit_button: {
		width: "100%",
	},
	title: {
		fontFamily: "'Poppins', sans-serif",
		fontWeight: "regular",
		marginBottom: theme.spacing(5),
	},
	logo: {
		width: 150,
		height: 150,
		borderRadius: theme.spacing(2),
	},
}));

export default function AuthScreen() {
	const classes = useStyles();
	const {enqueueSnackbar} = useSnackbar();
	const [loading, setLoading] = useState(false);

	const microsoftSign = async () => {
		try {
			setLoading(true);
			const provider = new firebase.auth.OAuthProvider("microsoft.com");
			auth.signInWithRedirect(provider).catch((error) => {
				enqueueSnackbar("Something went wrong", {
					variant: "error",
					TransitionComponent: Grow,
				});
				console.error(error);
				setLoading(false);
			});
		} catch (e) {
			console.error(error);
			setLoading(false);
		}
	};

	const emailSign = async ({email, password}) => {
		try {
			setLoading(true);
			auth.signInWithEmailAndPassword(email, password).catch((error) => {
				let error_string = "Something went wrong";
				if (error?.code === "auth/user-not-found")
					error_string = "User not found";
				else if (error?.code === "auth/wrong-password")
					error_string = "Wrong password";

				enqueueSnackbar(error_string, {
					variant: "error",
					TransitionComponent: Grow,
				});
				console.error(error);
				setLoading(false);
			});
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	const validateEmailSign = async ({email}) => {
		let errors = {};
		if (!validator.isEmail(email)) errors.email = "Check your email";
		return errors;
	};

	return (
		<div className={classes.container}>
			<Logo />
			<Paper className={classes.auth_container}>
				<Formik
					initialValues={{
						email: "",
						password: "",
					}}
					onSubmit={emailSign}
					validate={validateEmailSign}
					validateOnBlur={false}
					validateOnChange={false}
					validateOnMount={false}
				>
					{({errors, touched, handleChange}) => {
						return (
							<Form>
								<TextField
									className={classes.textfields}
									label="Email"
									id="email"
									type="text"
									onChange={handleChange}
									error={errors.email && touched.email}
									helperText={
										errors.email && touched.email
											? errors.email
											: null
									}
									disabled={loading}
								/>
								<TextField
									className={classes.textfields}
									label="Password"
									id="password"
									type="password"
									onChange={handleChange}
									error={errors.password && touched.password}
									helperText={
										errors.password && touched.password
											? errors.password
											: null
									}
									disabled={loading}
								/>
								<Button
									className={classes.submit_button}
									variant="contained"
									color="primary"
									disabled={loading}
									type="submit"
								>
									{!loading && "Continue"}
									{loading && (
										<CircularProgress
											size={15}
											color="primary"
											style={{
												marginTop: 5,
												marginBottom: 5,
											}}
										/>
									)}
								</Button>
							</Form>
						);
					}}
				</Formik>
				<Divider className={classes.divider} />

				<SignWithMicrosoft onClick={microsoftSign} disabled={loading} />
			</Paper>
		</div>
	);
}
