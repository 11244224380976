import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {lighten, makeStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import {Clear, Create} from "@material-ui/icons";
import {extractSchema} from "@oncities/schemas/dist/helpers";

import algolia from "../config/algolia";
import {auth, firestore} from "../config/firebase";
import {Loading} from "../components";
import {hasPermissions} from "../helpers";
import {Button, TextField, Chip} from "@material-ui/core";
import {useHistory} from "react-router";
import {useSelector} from "react-redux";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		padding: theme.spacing(4),
	},
	paper: {
		width: "100%",
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	fab: {
		position: "fixed",
		bottom: theme.spacing(4),
		right: theme.spacing(4),
	},
	thumbnail: {
		width: 90,
		height: "auto",
		display: "block",
	},
	avatar: {
		width: 50,
		height: 50,
		borderRadius: 25,
	},
}));

const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	highlight: {
		color: theme.palette.secondary.main,
		backgroundColor: lighten(theme.palette.secondary.light, 0.85),
	},
	title: {
		flex: "1 1 100%",
	},
}));

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, algolia = null) {
	let items = array;

	if (algolia !== null) {
		let sortOrder = algolia.map((m) => m.id);
		array = array.filter((f) => sortOrder.find((a) => a === f.id));

		const itemPositions = {};
		for (const [index, id] of sortOrder.entries()) {
			itemPositions[id] = index;
		}

		let res = array.sort((a, b) => itemPositions[a.id] - itemPositions[b.id]);
		items = res;
	}

	const stabilizedThis = items.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
	const {classes, order, orderBy, onRequestSort, disabled, headCells} = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align="left"
						padding="default"
						sortDirection={orderBy === headCell.id ? order : false}
						width={
							headCell.tableCellWidth ? headCell.tableCellWidth : "auto"
						}
					>
						{headCell.sortable && (
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
								disabled={disabled}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</span>
								) : null}
							</TableSortLabel>
						)}
						{!headCell.sortable && <span>{headCell.label}</span>}
					</TableCell>
				))}
				<TableCell width={100}></TableCell>
			</TableRow>
		</TableHead>
	);
}

const SearchBar = ({onChange = () => {}, searchText}) => {
	return (
		<TextField
			variant="outlined"
			placeholder="Search..."
			size={"small"}
			onChange={(e) => onChange(e.target.value)}
			value={searchText}
			InputProps={{
				startAdornment:
					searchText?.length > 0 ? (
						<IconButton
							onClick={() => onChange("")}
							disabled={!searchText}
							style={{order: 1, padding: 9}}
						>
							<Clear color="disabled" fontSize="small" />
						</IconButton>
					) : null,
			}}
		/>
	);
};

const EnhancedTableToolbar = (props) => {
	const classes = useToolbarStyles();
	const {numSelected, onChange, searchText, isAdmin} = props;

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			<SearchBar onChange={onChange} searchText={searchText} />
		</Toolbar>
	);
};

export default function AdminTable({entity, user, schemaFn}) {
	const classes = useStyles();
	const history = useHistory();
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [items, setItems] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [algoliaResults, setAlgoliaResults] = useState(null);
	const [collections, setCollections] = useState(null);
	const schema = schemaFn();
	const collect = schema
		.filter((f) => typeof f.entity !== "undefined" && f.tableCell === true)
		.map((f) => ({entity: f.entity, id: f.id, optionLabel: f.optionLabel}));

	const headCells = extractSchema(
		schema,
		["label", "sortable", "tableCellWidth"],
		"tableCell"
	);

	useEffect(() => {
		let unlisten = firestore
			.collection(entity)
			.onSnapshot((snap) =>
				setItems(
					snap.docs.map((d) => schemaFn(d.data(), "extractSchemaValues"))
				)
			);
		return unlisten;
	}, []);

	useEffect(() => {
		if (searchText.length > 1) doAlgoliaSearch(searchText);
		else setAlgoliaResults(null);
	}, [searchText]);

	useEffect(() => {
		if (Array.isArray(items)) getCollections(items);
	}, [items]);

	const getCollections = async (items) => {
		let results = {};
		for (const collection of collect) {
			let data = {};
			for (const item of items) {
				if (typeof item[collection.id] === "string") {
					let res = await firestore
						.doc(`/${collection.entity}/${item[collection.id]}`)
						.get();
					if (res.exists) {
						res = res.data();
						res = res[collection.optionLabel] || "";
						data[item[collection.id]] = res;
					} else data[item[collection.id]] = "";
				}
			}
			results[collection.id] = data;
		}
		setCollections(results);
	};

	const getFieldDisplay = (data, field_id) => {
		const field = schema.find((a_field) => a_field.id === field_id);
		let display = data[field_id];
		if (field.input === "image") {
			if (Array.isArray(data[field_id]) && data[field_id].length > 0)
				display = (
					<img
						src={data[field_id][0]}
						alt="preview"
						className={classes[field_id]}
					/>
				);
			else display = null;
		} else if (field.input === "date") {
			display = moment(data[field_id] * 1000).fromNow();
		} else if (field.entity) {
			if (data[field_id] === null) display = "";
			else display = collections[field.id][data[field_id]];
		}
		return display;
	};

	const doAlgoliaSearch = async (text) => {
		const index = algolia.initIndex(entity);

		let {hits} = await index.search(text, {
			hitsPerPage: 100,
		});

		setAlgoliaResults(hits);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	let rowCount = items;
	if (algoliaResults !== null)
		rowCount = rowCount.filter((f) =>
			algoliaResults.find((x) => x.id === f.id)
		);

	rowCount = rowCount?.length || 0;

	if (items === null || collections == null) return <Loading />;

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<EnhancedTableToolbar
					disabled={isLoading}
					onChange={(e) => setSearchText(e)}
					searchText={searchText}
					isAdmin={hasPermissions("admin", user.user_permissions)}
				/>
				<TableContainer>
					<Table
						className={classes.table}
						aria-labelledby="Page list"
						aria-label="page list"
					>
						<EnhancedTableHead
							classes={classes}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={rowCount}
							disabled={isLoading}
							isAdmin={hasPermissions("admin", user.user_permissions)}
							headCells={headCells}
						/>
						<TableBody>
							{stableSort(
								items,
								getComparator(order, orderBy),
								algoliaResults
							)
								.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
								)
								.map((row) => {
									return (
										<TableRow hover tabIndex={-1} key={row.id}>
											{headCells.map((headCell) => {
												const field_display = getFieldDisplay(
													row,
													headCell.id
												);
												return (
													<TableCell key={headCell.id}>
														{field_display}
													</TableCell>
												);
											})}
											<TableCell>
												<Button
													size="small"
													variant="outlined"
													color="primary"
													onClick={() =>
														history.push(
															`/${entity}/edit/${row.id}`
														)
													}
													disabled={isLoading}
												>
													<Create
														style={{width: 14, marginRight: 4}}
													/>
													Edit
												</Button>
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 50, 100, 200]}
					component="div"
					count={items.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	);
}
