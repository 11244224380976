import React from "react";
import {AdminForm} from "../../layouts";
import {organization} from "@oncities/schemas";

function Create({edit}) {
	return (
		<AdminForm
			edit={edit}
			schemaFn={organization}
			singularName="Organization"
			entity="organizations"
		/>
	);
}

export default Create;
