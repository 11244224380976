const initialState = {
	selected: null,
	list: null,
};

// Null = loading
// False = none selected
// True = all selected
// Object = specific selected

export default (state = initialState, {type, payload}) => {
	switch (type) {
		case "SIGN_OUT":
			return initialState;
		case "SET_SELECTED_EVENT":
			return {
				...state,
				selected: payload,
			};
		case "SET_EVENT_LIST":
			return {
				...state,
				list: payload,
			};
		default:
			return state;
	}
};
