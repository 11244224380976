import {
	AppBar,
	Button,
	makeStyles,
	Toolbar,
	Typography,
} from "@material-ui/core";
import {Add, ArrowBackIos} from "@material-ui/icons";
import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";
import {hasPermissions, parsePath} from "../helpers";

const useStyles = makeStyles((theme) => ({
	appBar: {
		width: `calc(100% - ${220}px)`,
		marginLeft: 170,
		backgroundColor: "white",
		borderLeft: "none",
		marginTop: -1,
		color: "black",
		textAlign: "center",
	},
	toolBar: {
		justifyContent: "space-between",
	},
	title_btn: {
		textTransform: "none",
	},
}));

export default function AppBarComponent() {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const user = useSelector((state) => state.user);
	const [currentVars, setCurrentVars] = useState(null);
	const isAdmin = hasPermissions("admin", user.user_permissions);

	useEffect(() => {
		const path = parsePath(location);
		const current = {};
		switch (path[0]) {
			case "users":
				current.title = {
					label: (
						<Typography variant="h6" noWrap>
							Contacts
						</Typography>
					),
					path: "/users",
				};
				if (typeof path[1] === "undefined" && isAdmin)
					current.action = {
						label: "Create Contact",
						path: "/users/create",
					};
				break;
			case "events":
				current.title = {
					label: (
						<Typography variant="h6" noWrap>
							Events
						</Typography>
					),
					path: "/events",
				};
				if (
					typeof path[1] === "undefined" &&
					hasPermissions("admin", user.user_permissions)
				)
					current.action = {
						label: "Create Event",
						path: "/events/create",
					};
				break;
			case "sessions":
				current.title = {
					label: (
						<Typography variant="h6" noWrap>
							Sessions
						</Typography>
					),
					path: "/sessions",
				};
				if (
					typeof path[1] === "undefined" &&
					hasPermissions("admin", user.user_permissions)
				)
					current.action = {
						label: "Create Session",
						path: "/sessions/create",
					};
				break;
			case "organizations":
				current.title = {
					label: (
						<Typography variant="h6" noWrap>
							Organizations
						</Typography>
					),
					path: "/organizations",
				};
				if (typeof path[1] === "undefined")
					current.action = {
						label: "Create Organization",
						path: "/organizations/create",
					};
				break;
			default:
				current.title = {
					label: (
						<Typography variant="h6" noWrap>
							Home
						</Typography>
					),
					path: "/",
				};
				break;
		}
		setCurrentVars(current);
	}, [location]);

	return (
		<AppBar position="fixed" className={classes.appBar} variant="outlined">
			<Toolbar className={classes.toolBar}>
				{currentVars && currentVars.title && !currentVars.action && (
					<Button
						variant="text"
						size="large"
						onClick={() => history.push(currentVars.title.path)}
						className={classes.title_btn}
					>
						<ArrowBackIos />
						{currentVars.title.label}
					</Button>
				)}
				{currentVars && currentVars.title && currentVars.action && (
					<>{currentVars.title.label}</>
				)}
				{currentVars && currentVars.action && isAdmin && (
					<Button
						variant="contained"
						color="primary"
						onClick={() => history.push(currentVars.action.path)}
						startIcon={<Add />}
					>
						{currentVars.action.label}
					</Button>
				)}
			</Toolbar>
		</AppBar>
	);
}
