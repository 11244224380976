import {TextField} from "@material-ui/core";
import React from "react";

export default function Text(props) {
	let extra_attrs = {};
	let has_error = !!props.error && !!props.touched;
	let helperText = "";
	let label = props.label;
	let type = props.input;
	let value = props.value;

	if (props.input === "textarea")
		extra_attrs = {rowsMax: 4, rows: 4, multiline: true};

	if (type === "textarea") type = "text";

	if (has_error) helperText = `${props.error}`;

	if (type === "color") {
		label = "";
		helperText = props.label;
	}

	if (
		type === "number" &&
		Number.isInteger(value) &&
		`${value}`.startsWith("0")
	)
		value = "";

	return (
		<TextField
			id={props.id}
			name={props.id}
			label={`${label} ${props.required === true ? "*" : ""}`}
			variant="outlined"
			error={has_error}
			value={value}
			onChange={props.handleChange}
			disabled={props.disabled}
			helperText={helperText}
			type={type}
			onBlur={() => props.setTouched(props.id, true)}
			autoComplete="off"
			{...extra_attrs}
		/>
	);
}
