import {makeStyles} from "@material-ui/core";
import React from "react";

import logo from "../images/logo_transparent.png";

const useStyles = makeStyles({
	logo: {width: 150, height: 150},
});

export default function Logo({className = ""}) {
	const classes = useStyles();

	return <img className={`${classes.logo} ${className}`} src={logo} />;
}
