import {makeStyles} from "@material-ui/core";
import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {auth, firestore, functions} from "./config/firebase";
import {
	addOrganizations,
	addProfessions,
	addKeyfields,
	signIn,
	signOut,
} from "./redux/actions";
import {Auth, Dashboard, Unauthorized} from "./screens";
import {Loading} from "./components";
import {user as schemaUser} from "@oncities/schemas";
import {setSelectedEvent, setEventList} from "./redux/actions";
import moment from "moment";

const useStyles = makeStyles({
	app: {
		width: "100%",
		height: "100%",
	},
});

export default function App() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const event = useSelector((state) => state.event);
	const prevUserPermissions = useRef(null);

	useEffect(() => {
		listenAuthentication();
	}, []);

	useEffect(() => {
		if (user !== null && user !== false) {
			let unlisten_org = firestore
				.collection(`/organizations`)
				.onSnapshot((snap) => {
					let res = snap.docs.map((m) => m.data());
					res = res.map((d) => ({
						id: d.id,
						name: d.name,
					}));
					dispatch(addOrganizations(res));
				});

			let unlisten_professions = firestore
				.collection(`/professions`)
				.onSnapshot((snap) => {
					let res = snap.docs.map((m) => m.data());
					res = res.map((d) => ({
						id: d.id,
						name: d.name,
					}));
					dispatch(addProfessions(res));
				});

			let unlisten_keyfields = firestore
				.collection(`/keyfields`)
				.onSnapshot((snap) => {
					let res = snap.docs.map((m) => m.data());
					res = res.map((d) => ({
						id: d.id,
						name: d.name,
					}));
					dispatch(addKeyfields(res));
				});

			return () => {
				unlisten_org();
				unlisten_professions();
				unlisten_keyfields();
			};
		}
	}, [user]);

	useEffect(() => {
		if (user !== null) {
			if (user?.user_permissions) {
				if (
					JSON.stringify(user?.user_permissions) !==
					prevUserPermissions.current
				)
					getEventsList();
			} else if (JSON.stringify(user) !== prevUserPermissions.current)
				dispatch(setEventList([]));

			if (user?.user_permissions)
				prevUserPermissions.current = JSON.stringify(
					user?.user_permissions
				);
			else prevUserPermissions.current = JSON.stringify(user);
		}
	}, [user]);

	useEffect(() => {
		if (
			(event.selected === null || event.selected === false) &&
			event.list !== null
		) {
			if (user?.user_permissions?.admin === true) {
				dispatch(setSelectedEvent(true));
			} else if (event.list.length === 0) {
				dispatch(setSelectedEvent(false));
			} else {
				if (event.list.length === 1)
					dispatch(setSelectedEvent(event.list[0].id));
				else dispatch(setSelectedEvent(true));
			}
		}
	}, [event.list]);

	const getEventsList = async () => {
		if (user?.user_permissions?.admin === true) {
			let all_events = await firestore.collection(`/events`).get();
			all_events = all_events.docs.map((d) => d.data());
			dispatch(setEventList(all_events));
		} else {
			let editor_events = user?.user_permissions?.editor || [];
			let moderator_events = user?.user_permissions?.moderator || [];
			moderator_events = moderator_events.map((m) => m[0]);

			let all_events = [...editor_events, ...moderator_events];
			all_events = new Set([...all_events]);
			all_events = [...all_events];
			let events_data = [];
			for (const event of all_events) {
				let res = await firestore.doc(`/events/${event}`).get();
				res = res.data();
				events_data.push(res);
			}

			dispatch(setEventList(events_data));
		}
	};

	const listenAuthentication = async () => {
		// Check if the local firebase auth state changed
		auth.onAuthStateChanged(async (user) => {
			// If no user, then sign out
			if (!user) dispatch(signOut());
			// If there is a user, open a real-time listener to the document
			else {
				firestore.doc(`/users/${user.uid}`).onSnapshot(
					async (snap) => {
						// If the user document exists
						if (snap.exists) {
							// Set user in redux
							dispatch(
								signIn(schemaUser(snap.data(), "extractSchemaValues"))
							);
						}
						// If there is no user document (New user)
						else {
							// Get the data from the firebase auth
							let auth_data = {
								firstname: user.displayName?.split(" ")[0] || "",
								lastname: user.displayName?.split(" ")[1] || "",
								email: user.email || null,
								avatar: user.photoURL || [],
								phone: user.phoneNumber || null,
								id: user.uid || null,
							};

							let fn = functions.httpsCallable("registerUser");
							let response = await fn({data: auth_data});
							response = response.data;

							// If an error happens, signout
							if (response === false) dispatch(signOut());

							// Give a second to firestore rules to catch new permissions
							setTimeout(() => {
								dispatch(signIn(response));
							}, 1000);
						}
					},
					(e) => {
						console.log("ERROR #1", e);
						dispatch(signOut());
					}
				);
			}
		});
	};

	if (user === null || event.selected === null) return <Loading />;

	if (
		user &&
		user.user_permissions.admin === false &&
		user.user_permissions.editor.length === 0 &&
		user.user_permissions.moderator.length === 0
	)
		return <Unauthorized />;

	return (
		<div className={classes.app}>
			<BrowserRouter>
				<Switch>
					<Route path="/auth" exact>
						{!user && <Auth />}
						{user && <Redirect to="/" />}
					</Route>
					<Route path="/">
						{!user && <Redirect to="/auth" />}
						{user && <Dashboard />}
					</Route>
					<Route path="/">
						<Redirect to="/" />
					</Route>
				</Switch>
			</BrowserRouter>
		</div>
	);
}
