import {makeStyles} from "@material-ui/core";
import React from "react";
import {Redirect, Route, Switch} from "react-router";

import Create from "./create";
import {hasPermissions} from "../../helpers";
import Root from "./root";
import {useSelector} from "react-redux";

const useStyles = makeStyles({
	container: {
		flexGrow: 1,
	},
});

export default function Events() {
	const classes = useStyles();

	const user = useSelector((state) => state.user);
	const isAdmin = hasPermissions("admin", user.user_permissions);

	return (
		<div className={classes.container}>
			<Switch>
				{isAdmin && (
					<Route path="/sessions/edit/:id" exact>
						<Create edit />
					</Route>
				)}
				{isAdmin && (
					<Route path="/sessions/create" exact>
						<Create />
					</Route>
				)}
				<Route path="/sessions" exact>
					<Root />
				</Route>
				<Route path="/sessions">
					<Redirect to="/sessions" />
				</Route>
			</Switch>
		</div>
	);
}
