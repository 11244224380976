import React, {useEffect, useState} from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";

import algolia from "../../config/algolia";
import {TextField} from "@material-ui/core";

export default function SelectComponent(props) {
	const [defaultVal, setDefaultVal] = useState(false);

	useEffect(() => {
		if (!!props.isAsync === true) setInitialDefault();
		else setDefaultVal(null);
	}, []);

	const formatValues = (v) => {
		if (Array.isArray(v) && v.length === 0) return [];
		if (v === null || !!v === false) return null;

		if (Array.isArray(v)) {
			let results = [];
			for (const item of v) {
				let value = item?.id || item?.value || "";
				let label = item?.label || item?.name || "";
				let result = {...item, label, value};
				results.push(result);
			}
			return results;
		} else {
			let value = v?.id || v?.value || "";
			let label = v?.label || v?.name || "";
			return {...v, label, value};
		}
	};

	const setInitialDefault = async () => {
		let hits = await getHits("");
		hits = hits.map((h) => ({
			label: h[props.optionLabel],
			value: h.id || h.value,
		}));
		setDefaultVal(hits);
	};

	const getHits = async (input = "") => {
		const index = algolia.initIndex(props.searchEntity || props.entity);
		let {hits} = await index.search(input, {
			hitsPerPage: 10,
		});
		return hits;
	};

	const loadOptions = async (input = "", callback) => {
		let hits = await getHits(input);
		callback(
			hits.map((h) => ({
				label: h[props.optionLabel],
				value: h.id || h.value,
			}))
		);
	};

	let has_error = !!props.error && !!props.touched;
	let helperText = "";
	if (has_error) helperText = props.errorMessage;

	let input = null;

	if (defaultVal === false)
		return <TextField label={props.label} disabled variant="filled" />;

	if (!!props.isAsync === true) {
		if (!!props.creatable === true) {
			input = (
				<AsyncCreatableSelect
					placeholder={props.label}
					isClearable
					onChange={(v) => {
						console.log("ONCHANGED", v);
						props.setFieldValue(props.id, v);
					}}
					value={formatValues(props.value)}
					isMulti={!!props.multiple === true}
					className={`creatable-container async-creatable z-index-${
						props.zIndex
					} ${has_error ? "invalid" : ""}`}
					classNamePrefix="creatable"
					isDisabled={props.disabled}
					loadOptions={loadOptions}
					cacheOptions
					defaultOptions={defaultVal}
				/>
			);
		} else {
			input = (
				<AsyncSelect
					placeholder={props.label}
					isClearable
					onChange={(v) => props.setFieldValue(props.id, v)}
					value={formatValues(props.value)}
					isMulti={!!props.multiple === true}
					className={`creatable-container async-creatable z-index-${
						props.zIndex
					} ${has_error ? "invalid" : ""}`}
					classNamePrefix="creatable"
					isDisabled={props.disabled}
					loadOptions={loadOptions}
					cacheOptions
					defaultOptions={defaultVal}
				/>
			);
		}
	} else {
		if (!!props.creatable === true) {
			input = (
				<CreatableSelect
					placeholder={props.label}
					isClearable
					onChange={(v) => props.setFieldValue(props.id, v)}
					value={formatValues(props.value)}
					isMulti={!!props.multiple === true}
					className={`creatable-container z-index-${props.zIndex}  ${
						has_error ? "invalid" : ""
					}`}
					classNamePrefix="creatable"
					isDisabled={props.disabled}
					options={
						props.options &&
						props.options.map((m) => {
							const label = m?.name || m?.label || m;
							const value = m?.id || m?.value || m;
							return {
								label,
								value,
							};
						})
					}
				/>
			);
		} else {
			input = (
				<Select
					placeholder={props.label}
					isClearable
					onChange={(v) => props.setFieldValue(props.id, v)}
					value={formatValues(props.value)}
					isMulti={!!props.multiple === true}
					className={`creatable-container z-index-${props.zIndex}  ${
						has_error ? "invalid" : ""
					}`}
					classNamePrefix="creatable"
					isDisabled={props.disabled}
					options={
						props.options &&
						props.options.map((m) => {
							const label = m?.name || m?.label || m;
							const value = m?.id || m?.value || m;
							return {
								label,
								value,
							};
						})
					}
				/>
			);
		}
	}

	return (
		<>
			{input}
			{helperText && (
				<span className="helper-text invalid">{helperText}</span>
			)}
		</>
	);
}
