import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
} from "@material-ui/core";
import React from "react";

export default function CheckboxComponent(props) {
	return (
		<FormControl
			component="fieldset"
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<FormControlLabel
				disabled={props.disabled}
				control={
					<Checkbox
						checked={!!props.value}
						onChange={(e) => {
							if (props.ignoreField === true) {
								props.setFieldValue(props.valueRef, e.target.checked);
							}
							props.setFieldValue(props.id, e.target.checked);
						}}
						name={props.id}
						id={props.id}
					/>
				}
				label={props.label}
			/>
		</FormControl>
	);
}
