import React from "react";
import {TextField, Grid} from "@material-ui/core";
import {Text, Image, Select, Checkbox} from "./fields";

function AdminField(props) {
	let field = (
		<TextField
			label={props.label}
			helperText="Field unavailable"
			disabled
			variant="filled"
		/>
	);

	if (props.input === "checkbox") field = <Checkbox {...props} />;
	else if (props.input === "select") field = <Select {...props} />;
	else if (props.input === "image") field = <Image {...props} />;
	else if (["text", "textarea", "number", "color"].includes(props.input))
		field = <Text {...props} />;

	return (
		<Grid item xs={props.width || 12}>
			{field}
		</Grid>
	);
}

export default AdminField;
