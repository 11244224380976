const initialState = null;

export default (state = initialState, {type, payload}) => {
	switch (type) {
		case "SIGN_OUT":
			return initialState;
		case "ADD_ORGANIZATIONS":
			return payload;
		case "REMOVE_ORGANIZATIONS":
			return [];
		default:
			return state;
	}
};
