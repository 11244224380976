import {createMuiTheme, CssBaseline, ThemeProvider} from "@material-ui/core";
import {SnackbarProvider} from "notistack";
import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {compose, createStore} from "redux";
import App from "./App";
import reducers from "./redux/reducers";
import muitheme from "./styles/muitheme";
import "./styles/index.scss";

const composeEnhancers =
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose();
const store = createStore(reducers, composeEnhancers());

const theme = createMuiTheme(muitheme);

ReactDOM.render(
	<>
		<Provider store={store}>
			<CssBaseline />
			<ThemeProvider theme={theme}>
				<SnackbarProvider
					maxSnack={3}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					<App />
				</SnackbarProvider>
			</ThemeProvider>
		</Provider>
	</>,
	document.getElementById("root")
);
