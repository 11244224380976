import {makeStyles} from "@material-ui/core";
import React from "react";
import {Redirect, Route, Switch} from "react-router";

import Root from "./root";
import Create from "./create";
import {useSelector} from "react-redux";
import {hasPermissions} from "../../helpers";

const useStyles = makeStyles({
	container: {
		flexGrow: 1,
	},
});

export default function Users() {
	const classes = useStyles();
	const user = useSelector((state) => state.user);

	return (
		<div className={classes.container}>
			<Switch>
				<Route path="/users/edit/:id" exact>
					<Create edit />
				</Route>
				{hasPermissions("admin", user.user_permissions) && (
					<Route path="/users/create">
						<Create />
					</Route>
				)}
				<Route path="/users" exact>
					<Root />
				</Route>
				<Route path="/users">
					<Redirect to="/" />
				</Route>
			</Switch>
		</div>
	);
}
