import React from "react";
import {user} from "@oncities/schemas";

import {AdminForm} from "../../layouts";
import {firestore} from "../../config/firebase";
import {hasPermissions} from "../../helpers";
import {useSelector} from "react-redux";

function Create({edit}) {
	const userData = useSelector((state) => state.user);

	const customSubmit = async (values, results, {setFieldError}) => {
		try {
			console.log("INCOMING VALUES", values);
			console.log("INCOMING RESULTS", results);

			if (!edit) {
				let usersWithSameEmail = await firestore
					.collection("/users")
					.where("email", "==", `${results.email}`.trim())
					.get();
				if (usersWithSameEmail.docs.length > 0) {
					setFieldError("email", "That email is already being used");
					return {
						results,
						next: false,
					};
				}
			}

			results.user_permissions = {
				...results.user_permissions,
				admin: hasPermissions("admin", userData.user_permissions)
					? results.user_permissions.admin || false
					: false,
			};

			return {
				results,
				next: true,
			};
		} catch (e) {
			console.error("ERROR", e);
			return {
				results,
				next: false,
			};
		}
	};

	return (
		<AdminForm
			customSubmit={customSubmit}
			edit={edit}
			schemaFn={user}
			singularName="User"
			entity="users"
			protectedFields={{
				is_admin: hasPermissions("admin", userData.user_permissions)
					? false
					: true,
			}}
		/>
	);
}

export default Create;
