import {
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	makeStyles,
	MenuItem,
	Select,
	Typography,
} from "@material-ui/core";
import {
	PeopleAlt,
	ExitToApp,
	Event,
	Videocam,
	Domain,
	DeveloperMode,
} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {auth} from "../config/firebase";
import {signOut, setSelectedEvent} from "../redux/actions";
import {Link, useLocation} from "react-router-dom";
import oncitiesIcon from "../images/logo_transparent.png";
import {hasPermissions, parsePath} from "../helpers";

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: "220px !important",
		flexShrink: 0,
		"& .MuiDrawer-paper": {
			width: "220px !important",
		},
	},
	toolbar: {
		...theme.mixins.toolbar,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	menu_icon: {
		width: 36,
		minWidth: 36,
	},
	event_switch_container: {
		padding: theme.spacing(1),
		paddingBottom: 0,
		width: "100%",
	},
	event_switch: {
		width: "100%",
	},
	title: {
		fontFamily: "Poppins, sans-serif",
		fontWeight: "600",
		"& a ": {
			display: "flex",
			alignItems: "center",
			color: "inherit",
			textDecoration: "none",
		},
		"& img": {
			width: 30,
			height: "auto",
			marginRight: "3px",
		},
	},
}));

export default function DrawerComponent() {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const [currentSection, setCurrentSection] = useState("");
	const user = useSelector((state) => state.user);
	const event = useSelector((state) => state.event);

	useEffect(() => {
		const path = parsePath(location);
		setCurrentSection(typeof path[0] !== "undefined" ? path[0] : "");
	}, [location]);

	const signout = () => {
		auth.signOut();
		dispatch(signOut());
		setTimeout(() => {
			window.location.reload();
		}, 500);
	};

	return (
		<Drawer anchor="left" className={classes.drawer} variant="permanent">
			<div className={classes.toolbar}>
				<Typography className={classes.title} variant="h6">
					<Link to="/">
						<img src={oncitiesIcon} /> <span>ONCITIES</span>
					</Link>
				</Typography>
			</div>
			<Divider />
			{Array.isArray(event.list) && (
				<div className={classes.event_switch_container}>
					<Select
						className={classes.event_switch}
						id="event-switch"
						value={event.selected}
						onChange={(ev) => {
							if (ev.target.value === "create")
								history.push("/events/create");
							else dispatch(setSelectedEvent(ev.target.value));
						}}
						variant="outlined"
					>
						{event.selected === false && (
							<MenuItem key="none" value={false}>
								None Selected
							</MenuItem>
						)}
						{(event.list.length > 1 ||
							hasPermissions("admin", user.user_permissions)) && (
							<MenuItem key="all" component="em" value={true}>
								All events
							</MenuItem>
						)}
						{event.list.map((ev) => (
							<MenuItem key={ev.id} value={ev.id}>
								{ev.name}
							</MenuItem>
						))}
						{hasPermissions("admin", user.user_permissions) && (
							<MenuItem value="create">
								<Typography
									key="create"
									style={{
										fontWeight: "bold",
									}}
								>
									Create new event
								</Typography>
							</MenuItem>
						)}
					</Select>
				</div>
			)}
			<List>
				<ListItem
					button
					onClick={() => history.push("/users")}
					selected={currentSection === "users"}
				>
					<ListItemIcon className={classes.menu_icon}>
						<PeopleAlt />
					</ListItemIcon>
					<ListItemText primary="Contacts" />
				</ListItem>
				{process.env.REACT_APP_IS_DEV === "true" && (
					<ListItem
						button
						onClick={() => history.push("/events")}
						selected={currentSection === "events"}
					>
						<ListItemIcon className={classes.menu_icon}>
							<Event />
						</ListItemIcon>
						<ListItemText primary="Events" />
					</ListItem>
				)}
				{process.env.REACT_APP_IS_DEV === "true" && (
					<ListItem
						button
						onClick={() => history.push("/sessions")}
						selected={currentSection === "sessions"}
					>
						<ListItemIcon className={classes.menu_icon}>
							<Videocam />
						</ListItemIcon>
						<ListItemText primary="Sessions" />
					</ListItem>
				)}
				<ListItem
					button
					onClick={() => history.push("/organizations")}
					selected={currentSection === "organizations"}
				>
					<ListItemIcon className={classes.menu_icon}>
						<Domain />
					</ListItemIcon>
					<ListItemText primary="Organizations" />
				</ListItem>
				{/* <ListItem
					button
					onClick={() => history.push("/tests")}
					selected={currentSection === "tests"}
				>
					<ListItemIcon className={classes.menu_icon}>
						<DeveloperMode />
					</ListItemIcon>
					<ListItemText primary="Tests" />
				</ListItem> */}
				<ListItem button onClick={signout}>
					<ListItemIcon className={classes.menu_icon}>
						<ExitToApp />
					</ListItemIcon>
					<ListItemText primary="Sign out" />
				</ListItem>
			</List>
		</Drawer>
	);
}
