import {Button, makeStyles} from "@material-ui/core";
import React from "react";

import microsoftSvg from "../images/microsoft.svg";

const useStyles = makeStyles({
	btn: {
		width: "100%",
	},
});

export default function SignWithMicrosoft({
	onClick = () => {},
	disabled = false,
}) {
	const classes = useStyles();

	return (
		<Button
			onClick={onClick}
			variant="contained"
			startIcon={<img src={microsoftSvg} width={20} height={20} />}
			color="primary"
			className={classes.btn}
			disabled={disabled}
		>
			Continue with Microsoft
		</Button>
	);
}
