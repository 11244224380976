import {user as schemaUser} from "@oncities/schemas";

const default_permissions = schemaUser({}, "extractSchemaValues")
	.user_permissions;

export default (
	required_role = "admin",
	perms = default_permissions,
	event_id = null,
	session_id = null
) => {
	let {user_permissions} = schemaUser(
		{user_permissions: perms},
		"extractSchemaValues"
	);

	if (required_role === "participant") return true;

	if (required_role === "moderator")
		if (
			typeof perms.moderator.find((f) => f[1] === session_id) !== "undefined"
		)
			return true;

	if (required_role === "editor" || required_role === "moderator")
		if (perms.editor.includes(event_id)) return true;

	if (
		required_role === "admin" ||
		required_role === "editor" ||
		required_role === "moderator"
	)
		if (user_permissions.admin === true) return true;

	return false;
};
