import {makeStyles} from "@material-ui/core";
import React from "react";
import {Redirect, Route, Switch} from "react-router";

import Root from "./root";
import Create from "./create";

const useStyles = makeStyles({
	container: {
		flexGrow: 1,
	},
});

export default function Events() {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<Switch>
				<Route path="/events/edit/:id" exact>
					<Create edit />
				</Route>
				<Route path="/events/create">
					<Create />
				</Route>
				<Route path="/events" exact>
					<Root />
				</Route>
				<Route path="/events">
					<Redirect to="/" />
				</Route>
			</Switch>
		</div>
	);
}
