import {combineReducers} from "redux";

import user from "./user";
import organizations from "./organizations";
import professions from "./professions";
import keyfields from "./keyfields";
import event from "./event";

export default combineReducers({
	user,
	organizations,
	professions,
	keyfields,
	event,
});
