import {
	Button,
	CircularProgress,
	makeStyles,
	Paper,
	Typography,
	IconButton,
	Grid,
	Popover,
} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import validator from "validator";
import {Form, Formik} from "formik";
import {useSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {firestore, storage} from "../config/firebase";
import {AdminField, Loading} from "../components";
import {MoreVert, Delete} from "@material-ui/icons";
import {
	extractSchemaValues,
	findSchemaValue,
} from "@oncities/schemas/dist/helpers";
import schemas from "@oncities/schemas";
import {genUUID} from "../helpers";

const useStyles = makeStyles((theme) => ({
	container: {
		flex: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		padding: theme.spacing(4),
		"& .MuiFormControl-root, & .MuiTypography-root": {
			textAlign: "left",
		},
	},
	title: {
		marginBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(4),
		width: 800,
		position: "relative",
		textAlign: "center",
		"& .MuiGrid-item": {
			"& > *": {
				width: "100%",
			},
		},
	},
	options_button: {
		position: "absolute",
		top: theme.spacing(2),
		right: theme.spacing(2),
	},
	danger_button: {
		color: red[700],
	},
}));

export default function AdminForm({
	edit,
	entity,
	singularName,
	schemaFn,
	customSubmit = () => true,
	preventDefault = false,
	protectedFields = {},
}) {
	const history = useHistory();
	const params = useParams();
	const classes = useStyles();
	const {enqueueSnackbar} = useSnackbar();
	const [loading, setLoading] = useState(false);
	const [item, setItem] = useState(null);
	const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

	const options_popover_open = Boolean(popoverAnchorEl);
	const options_popover_id = options_popover_open
		? "options-popover"
		: undefined;

	useEffect(() => {
		if (edit) getItemDoc();
		else setItem(false);
	}, []);

	const handlePopoverClick = (evt) => {
		setPopoverAnchorEl(evt.currentTarget);
	};

	const handlePopoverClose = () => {
		setPopoverAnchorEl(null);
	};

	const getAsyncOption = async (ent, requested_field, item_id) => {
		try {
			if (item_id === null) return null;
			let op_res = await firestore.doc(`/${ent}/${item_id}`).get();
			if (op_res.exists) {
				op_res = op_res.data();
				return {
					label: op_res[requested_field],
					value: item_id,
				};
			} else {
				return null;
			}
		} catch (e) {
			console.log("ERROR GETTING OPTION", e, ent, item_id);
		}
	};

	const getItemDoc = async () => {
		let res = await firestore.doc(`/${entity}/${params.id}`).get();
		let data = false;
		if (res.exists) {
			let full_schema = schemaFn(res.data());
			data = extractSchemaValues(full_schema);
			for (const field of full_schema) {
				if (field.optionLabel && field.input === "select" && field.entity) {
					if (field.multiple === true && Array.isArray(field.value)) {
						let temp = [];
						for (const a_val of field.value) {
							let aop = await getAsyncOption(
								field.entity,
								field.optionLabel,
								a_val
							);
							if (aop !== null) temp.push(aop);
						}

						data[field.id] = temp;
					} else {
						data[field.id] = await getAsyncOption(
							field.entity,
							field.optionLabel,
							field.value
						);
					}
				}
			}
		}
		setItem(data);
	};

	const validate = (values) => {
		let errors = {};
		const schema = schemaFn();
		for (const field of schema.filter((f) => !f.meta)) {
			let is_valid = true;
			if (field.multiple) {
				if (
					Array.isArray(values[field.id]) &&
					values[field.id].length > 0
				) {
					for (const a_subvalue of values[field.id]) {
						let a_subfield_validation = validateField(
							field.id,
							null,
							a_subvalue,
							field.required === true,
							field.input,
							field.maxLength
						);
						if (!a_subfield_validation.is_valid) {
							is_valid = false;
							if (a_subfield_validation.error)
								field.error = a_subfield_validation.error;
							break;
						}
					}
				} else {
					is_valid = !field.required;
				}
			} else {
				let field_validation = validateField(
					field.id,
					field.value,
					values[field.id],
					field.required === true,
					field.input,
					field.maxLength
				);
				is_valid = field_validation.is_valid;
				if (field_validation.error) {
					field.error = field_validation.error;
				}
			}
			if (!is_valid) {
				if (!field.error) field.error = "Invalid field";
				errors[field.id] = field.error;
			}
		}
		return errors;
	};

	const validateField = (
		id,
		default_value,
		sent_value,
		required,
		field_type,
		maxLength = 999999,
		minLength = 1
	) => {
		let is_valid = true;
		let error = null;

		if (field_type === "text" && id === "email") {
			is_valid = validator.isEmail(sent_value);
			error = "Invalid email";
		} else {
			switch (field_type) {
				case "select":
					let has_value = Array.isArray(sent_value)
						? sent_value.length > 0
						: !!sent_value;
					is_valid = !has_value && required ? false : true;
					break;
				case "text":
				case "textarea":
				case "color":
				case "number":
					if (
						typeof sent_value === "string" ||
						typeof sent_value === "number"
					) {
						if (required) {
							is_valid = sent_value.length >= minLength;
							if (is_valid) {
								is_valid = sent_value.length <= maxLength;
								if (!is_valid)
									error = `Maximum length is ${maxLength} characters`;
							} else {
								if (sent_value.length === 0) error = "Required field";
								else if (sent_value.length === 1)
									error = `Minimum length is 1 character`;
								else
									error = `Minimum length is ${minLength} characters`;
							}
						}
					} else {
						is_valid = false;
					}
					break;
				default:
					break;
			}
		}

		return {
			is_valid,
			error,
		};
	};

	const submit = async (values, props) => {
		try {
			setLoading(true);
			const schema = schemaFn(values);
			const item_id = findSchemaValue("id", schema);
			let item_data = {};

			// Process data to leave only values
			for (const field of schema) {
				if (field.ignoreField !== true) {
					if (field.input === "select") {
						if (field.multiple === true && Array.isArray(field.value)) {
							item_data[field.id] = field.value.map((op) =>
								op.value && typeof op.value.value !== "undefined"
									? op.value.value
									: op.value
							);
						} else {
							item_data[field.id] =
								typeof field.value === "string"
									? field.value.trim()
									: field.value &&
									  typeof field.value.value !== "undefined"
									? field.value.value
									: field.value;
						}
					} else if (field.input === "image") {
						let image_url = null;
						if (
							typeof field.value === "string" &&
							validator.isDataURI(field.value)
						) {
							let storageRef = storage.ref();
							let imgRef = storageRef.child(
								`${entity}/${field.imageType.toLowerCase()}_${item_id}.jpg`
							);
							await imgRef.putString(field.value, "data_url");
							image_url = await imgRef.getDownloadURL();
						} else if (field.value && field.value.length > 0) {
							image_url =
								Array.isArray(field.value) &&
								`${field.value[0]}`.startsWith("http")
									? field.value[0]
									: typeof field.value === "string" &&
									  `${field.value}`.startsWith("http")
									? field.value
									: null;
						}
						item_data[field.id] = image_url ? [image_url] : [];
					} else if (field.input === "number") {
						let numb = parseInt(field.value);
						if (isNaN(numb)) numb = 0;
						item_data[field.id] = numb;
					} else {
						item_data[field.id] =
							typeof field.value === "string"
								? field.value.trim()
								: field.value;
					}
				}
			}

			// Middleware
			let {results, next} = await customSubmit(values, item_data, props);
			item_data = results;

			if (next === false) {
				setLoading(false);
				return;
			}

			if (next === true && preventDefault === true) {
				setLoading(false);
				history.push(`/${entity}`);
				return;
			}

			// Process creatable
			for (const field of schema) {
				if (
					field.input === "select" &&
					field.creatable === true &&
					typeof field.entity === "string" &&
					field.isAsync === true
				) {
					let oldValues = [];
					let newValues = null;
					if (field.multiple === true) {
						let vals = (field.value || []).filter(
							(f) => f?.__isNew__ === true
						);
						oldValues = (field.value || []).filter(
							(f) => f?.__isNew__ !== true
						);
						oldValues = oldValues.map((o) => o.value);
						if (vals.length > 0) newValues = vals;
					} else {
						if (field?.value?.__isNew__ === true) newValues = field.value;
					}

					if (newValues !== null) {
						// Is assumed that entity is in plural
						// The default case to convert in singular
						// Is to remove an s at the end.
						// If this does not apply, create another rule.

						let singularSchema = `${field.entity}`.slice(0, -1);
						let schema = schemas[singularSchema];
						if (typeof schema !== "undefined") {
							const setEntity = async (label) => {
								let id = genUUID();
								let schemaValues = schema(
									{
										id,
										label,
										value: id,
										name: label,
									},
									"extractSchemaValues"
								);
								await firestore
									.doc(`/${field.entity}/${id}`)
									.set(schemaValues);
								return id;
							};

							if (field.multiple === true) {
								let vals = [];
								for (const val of newValues) {
									let result = await setEntity(val?.label);
									vals.push(result);
								}
								item_data[field.id] = [...vals, ...oldValues];
							} else {
								let result = await setEntity(newValues?.label);
								item_data[field.id] = result;
							}
						}
					}
				}
			}

			// Create entity
			if (edit) {
				await firestore.runTransaction(async (trans) => {
					let _data = await trans.get(
						firestore.doc(`/${entity}/${item_id}`)
					);
					_data = _data.data();

					if (!_data)
						await firestore.doc(`/${entity}/${item_id}`).set(item_data);

					if (item_data.created && _data.created) delete item_data.created;

					for (const field of schema) {
						if (
							typeof item_data[field.id] === "string" ||
							typeof item_data[field.id] === "number"
						) {
							if (item_data[field.id] === item[field.id]) {
								delete item_data[field.id];
							}
						} else if (Array.isArray(item[field.id])) {
							if (
								JSON.stringify(item_data[field.id]) ===
								JSON.stringify(item[field.id].map((m) => m.value))
							) {
								delete item_data[field.id];
							}
						} else {
							if (
								JSON.stringify(item_data[field.id]) ===
								JSON.stringify(item[field.id])
							) {
								delete item_data[field.id];
							}
						}
					}

					const merged_data = {
						..._data,
						...item_data,
					};

					await trans.update(
						firestore.doc(`/${entity}/${item_id}`),
						merged_data
					);
				});
			} else {
				await firestore.doc(`/${entity}/${item_id}`).set(item_data);
			}
			setLoading(false);
			history.push(`/${entity}`);
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(
				`An error occurred while saving your ${singularName.toLowerCase()} [${
					error.message
				}]`,
				{variant: "error"}
			);
		}
	};

	if (item === null) return <Loading />;

	return (
		<div className={classes.container}>
			<Paper className={classes.paper}>
				{edit && (
					<>
						<IconButton
							disabled={loading}
							className={classes.options_button}
							aria-describedby={options_popover_id}
							onClick={handlePopoverClick}
						>
							<MoreVert />
						</IconButton>
						<Popover
							id={options_popover_id}
							open={options_popover_open}
							anchorEl={popoverAnchorEl}
							onClose={handlePopoverClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
						>
							<Button
								className={classes.danger_button}
								onClick={() => {
									var r = confirm(
										`Are you sure you want to delete this ${singularName.toLowerCase()}?`
									);
									if (r == true) {
										firestore.doc(`/${entity}/${item.id}`).delete();
										history.push(`/${entity}`);
									}
								}}
							>
								<Delete />
								Delete {singularName.toLowerCase()}
							</Button>
						</Popover>
					</>
				)}

				<Typography variant="h5" component="h1" className={classes.title}>
					{!edit && `Create ${singularName}`}
					{edit && `Edit ${singularName}`}
				</Typography>
				<Formik
					initialValues={extractSchemaValues(schemaFn(item))}
					validate={validate}
					validateOnMount={false}
					validateOnChange={false}
					validateOnBlur={true}
					onSubmit={submit}
				>
					{({
						values,
						handleChange,
						errors,
						touched,
						setFieldValue,
						setFieldTouched,
					}) => {
						console.log("VALUES", values);

						const schema = schemaFn(values);
						const form_fields_schema = schema.filter(
							(field) => !field.meta
						);

						return (
							<Form autoComplete="off">
								<Grid container spacing={2}>
									{form_fields_schema.map((field) => {
										return (
											<AdminField
												key={field.id}
												{...field}
												error={errors[field.id]}
												touched={touched[field.id]}
												handleChange={handleChange}
												setFieldValue={setFieldValue}
												setTouched={setFieldTouched}
												disabled={
													loading ||
													protectedFields[field.id] === true
												}
											/>
										);
									})}
									<Grid item xs={12}>
										<Button
											variant="contained"
											color="primary"
											disabled={loading}
											type="submit"
											size="large"
										>
											{!loading && !edit && `Create ${singularName}`}
											{!loading && edit && `Update ${singularName}`}
											{loading && (
												<CircularProgress
													size={20}
													style={{
														marginTop: 5,
														marginBottom: 5,
													}}
												/>
											)}
										</Button>
									</Grid>
								</Grid>
							</Form>
						);
					}}
				</Formik>
			</Paper>
		</div>
	);
}
