import {CircularProgress, colors, makeStyles} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
	container: {
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: colors.grey[100],
	},
});

export default function Loading() {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<CircularProgress size={35} color="primary" />
		</div>
	);
}
