import React from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import {
	TextField,
	Grid,
	Avatar,
	Typography,
	IconButton,
	makeStyles,
} from "@material-ui/core";
import {CloudUpload, CameraAlt} from "@material-ui/icons";
import default_cover from "../../images/cover-sample.png";
import default_logo from "../../images/logo-sample.png";
import default_image from "../../images/image-sample.png";
import algolia from "../../config/algolia";

const useStyles = makeStyles((theme) => ({
	hide: {
		position: "fixed",
		top: "-999em",
	},
	Image: {
		margin: "0 auto",
		width: "100% !important",
		display: "block",
		position: "relative",
		cursor: "pointer",
		border: `${theme.palette.grey[300]} 1px dashed`,
		backgroundColor: theme.palette.grey[100],
		"&.invalid": {
			backgroundColor: theme.palette.red[100],
			borderColor: theme.palette.red[400],
			"& + .helper-text": {
				textAlign: "center",
				marginLeft: 0,
			},
			"& .MuiIconButton-root": {
				color: theme.palette.red[400],
			},
		},
		"& p": {
			marginBottom: theme.spacing(2),
			position: "absolute",
			top: theme.spacing(2),
			left: 0,
			padding: `0 ${theme.spacing(2)}px`,
		},
		"& .MuiIconButton-root": {
			position: "absolute",
			bottom: theme.spacing(2.5),
			right: theme.spacing(2.5),
			backgroundColor: "white",
			color: theme.palette.grey[600],
		},
		"& img": {
			width: "100%",
			height: "auto",
			display: "block",
		},
		"&[disabled]": {
			opacity: "0.4",
			cursor: "default",
		},
		"&:not([disabled])": {
			"&:hover, &:active, &:focus": {
				"& .MuiIconButton-root": {
					transform: "scale(1.2)",
				},
			},
		},
	},
	Logo: {
		maxWidth: 320,
		"& img": {
			height: "auto",
		},
	},
	Avatar: {
		border: "none",
		background: "transparent",
		width: "90px !important",
		height: 90,
		"&.invalid": {
			background: "transparent",
			"& .MuiAvatar-root": {
				backgroundColor: theme.palette.red[200],
			},
		},
		"& .MuiIconButton-root": {
			bottom: -6,
			right: -6,
			top: "auto",
			left: "auto",
		},
		"& .MuiAvatar-root": {
			width: 90,
			height: 90,
			backgroundColor: theme.palette.grey[200],
		},
	},
}));

export default function Image(props) {
	const classes = useStyles();

	const handleImageChange = (data, id, setImage) => {
		const file = data.target.files[0];
		const reader = new FileReader();
		reader.addEventListener(
			"load",
			function () {
				setImage(id, reader.result);
			},
			false
		);

		if (file) reader.readAsDataURL(file);
	};

	let default_img =
		props.imageType === "Avatar"
			? null
			: props.imageType === "Cover"
			? default_cover
			: props.imageType === "Logo"
			? default_logo
			: default_image;
	let img_src =
		Array.isArray(props.value) && props.value.length > 0
			? props.value[0]
			: typeof props.value === "string"
			? props.value
			: default_img;
	return (
		<>
			<label
				htmlFor={props.id}
				className={`${classes.Image} ${classes[props.imageType]}`}
				disabled={props.disabled}
			>
				<Typography>{`Upload ${props.label}:`}</Typography>
				{props.imageType === "Avatar" ? (
					<Avatar src={img_src} />
				) : (
					<img src={img_src} alt="preview" className={classes.preview} />
				)}

				<input
					className={classes.hide}
					accept={props.accept}
					disabled={props.disabled}
					id={props.id}
					type="file"
					onChange={(v) =>
						handleImageChange(v, props.id, props.setFieldValue)
					}
				/>
				<IconButton component="span">
					{props.imageType === "Avatar" ? (
						<CameraAlt />
					) : (
						<CloudUpload style={{fontSize: 40}} />
					)}
				</IconButton>
			</label>
		</>
	);
}
