import {makeStyles} from "@material-ui/core";
import React from "react";
import {Redirect, Route, Switch} from "react-router";
import {AppBar} from "../components";
import Drawer from "../components/Drawer";
import Events from "./events";
import Sessions from "./sessions";
import Organizations from "./organizations";
import Users from "./users";
import Tests from "./tests";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: "100%",
	},
}));

export default function Dashboard() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<AppBar />
			<Drawer />
			<main className={classes.content}>
				<div className={classes.toolbar} />
				<Switch>
					<Route path="/users">
						<Users />
					</Route>
					{process.env.REACT_APP_IS_DEV === "true" && (
						<Route path="/events">
							<Events />
						</Route>
					)}
					{process.env.REACT_APP_IS_DEV === "true" && (
						<Route path="/sessions">
							<Sessions />
						</Route>
					)}
					{/* {process.env.REACT_APP_IS_DEV === "true" && (
						<Route path="/tests">
							<Tests />
						</Route>
					)} */}
					<Route path="/organizations">
						<Organizations />
					</Route>
					<Route path="/">
						<Redirect to="/users" />
					</Route>
				</Switch>
			</main>
		</div>
	);
}
