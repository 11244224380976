import firebase from "firebase/app";
import "firebase/storage";
import "firebase/functions";
import "firebase/auth";
import "firebase/firestore";

firebase.initializeApp({
	apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
	appId: process.env.REACT_APP_FIREBASE_APPID,
});

export const auth = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.functions();
export const firestore = firebase.firestore();
export default firebase;
