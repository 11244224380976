import {blue, grey, red} from "@material-ui/core/colors";
import {colors} from "@oncities/schemas/dist/constants";

export default {
	palette: {
		primary: {...grey, main: colors.black},
		secondary: {...blue, main: colors.blue},
		danger: {...red, main: colors.red},
		grey: {...grey, main: colors.grey},
		red: {...red, main: colors.red},
	},
	typography: {
		fontSize: 12,
		fontWeight: 400,
		fontWeightBold: 600,
		fontFamily: [
			"Poppins",
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		button: {
			fontWeight: 600,
		},
	},

	overrides: {
		MuiTableCell: {
			head: {
				fontWeight: "600",
			},
		},
		MuiCssBaseline: {
			"@global": {
				html: {
					height: "100%",
					width: "100%",
				},
				body: {
					height: "100%",
					width: "100%",
				},
			},
		},
	},
};
