import {Button, colors, makeStyles, Typography} from "@material-ui/core";
import React from "react";
import {useDispatch} from "react-redux";
import {auth} from "../config/firebase";
import {signOut as signOutAction} from "../redux/actions";

const useStyles = makeStyles({
	container: {
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		backgroundColor: colors.grey[100],
	},
	signout: {
		marginTop: 30,
	},
});

export default function Unauthorized() {
	const classes = useStyles();
	const dispatch = useDispatch();

	const signOut = () => {
		dispatch(signOutAction());
		auth.signOut();
		setTimeout(() => {
			window.location.reload();
		}, 500);
	};

	return (
		<div className={classes.container}>
			<Typography variant="h1" component="h1">
				Unauthorized
			</Typography>
			<Typography
				variant="h6"
				component="h2"
				style={{
					maxWidth: 600,
					textAlign: "center",
					marginTop: 15,
					marginBottom: 15,
				}}
			>
				You don't have enough permissions to access <em>OnCities Admin</em>,
				please ask an administrator to give you the required permissions.
			</Typography>

			<div
				style={{
					display: "flex",
					flexDirection: "row",
				}}
			>
				<Button
					variant="contained"
					color="primary"
					onClick={signOut}
					className={classes.signout}
					style={{
						marginRight: 5,
					}}
				>
					Sign out
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => window.location.reload()}
					className={classes.signout}
					style={{
						marginLeft: 5,
					}}
				>
					Reload
				</Button>
			</div>
		</div>
	);
}
