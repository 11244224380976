const initialState = null;

export default (state = initialState, {type, payload}) => {
	switch (type) {
		case "SIGN_OUT":
			return false;
		case "SIGN_IN":
			return payload;
		case "UPDATE_USER":
			let prev = state || {};
			return {...payload, ...prev};
		default:
			return state;
	}
};
