import React from "react";
import {AdminTable} from "../../layouts";
import {useSelector} from "react-redux";
import {organization} from "@oncities/schemas";

export default function Organizations() {
	const user = useSelector((state) => state.user);
	return (
		<AdminTable entity="organizations" user={user} schemaFn={organization} />
	);
}
