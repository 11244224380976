import algoliasearch from "algoliasearch";

// Prod
// const appId = "HX6NZ0JJJY";
// const appKey = "97f3764af780cb37fc310e782c7eda9d";

// Dev
const appId = process.env.REACT_APP_ALGOLIA_APPID;
const appKey = process.env.REACT_APP_ALGOLIA_APPKEY;

export default algoliasearch(appId, appKey);
